import React, { useContext, useEffect, useLayoutEffect } from "react";
import { ThemeContext } from "styled-components";
import { graphql } from "gatsby";
import { compose, get } from "lodash/fp";

import Head from "components/Head";
import Header from "components/PageHeader";
import Grid from "components/collection/Grid";

import Fade from "transitions/Fade";

const Collection = ({ ...props }) => {
  const collection = compose(get("data.shopify.nodes[0]"))(props);
  const collectionImage = get("image.originalSrc")(
    collection
  );
  const isBrowser = typeof window !== 'undefined';
  const theme = useContext(ThemeContext);

  useEffect(() => {
    theme.global.setHeaderPadding(false);
  }, [theme.global]);

  useLayoutEffect(() => {
    if (isBrowser) {
      const scrollPosition = sessionStorage.getItem('scrollPosition');
      if (scrollPosition && collection.products) {
        window.scrollTo(0, parseInt(scrollPosition, 10));
        sessionStorage.removeItem('scrollPosition');
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  },[collection.products])

  return (
    <Fade>
      <Head
        title={collection?.seo?.title ?? collection.title}
        description={collection?.seo?.description ?? collection.description}
        image={collectionImage}
      />
      <Header
        title={collection.title}
        description={collection.descriptionHtml}
        image={collection.image}
        layout="Hero"
        noMargin
      />
      <Grid {...collection} />
    </Fade>
  );
};

export const query = graphql`
  query CollectionQuery($collectionId: String!) {
    shopify: allShopifyCollection(
      filter: { storefrontId: { eq: $collectionId } }
    ) {
      nodes {
        storefrontId
        title
        handle
        description
        descriptionHtml
        seo {
          title
          description
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
          originalSrc
        }
        products {
          ...Product
        }
      }
    }
  }
`;

export default Collection;
